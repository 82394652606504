import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import businessOrderService from '../services/business/orderService';
import catcher from '../util/catcher';
import { ORDER_STATUS } from '../util/constants';
import useTranslate from '../util/dictionary';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Order from './order';

const OrderHistoryController = () => {

    const tr = useTranslate();
    const navigateTo = useNavigate();
    const { counterPasscode } = useParams();

    const [date, setDate] = useState(new Date());
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getOrderHistory = async () => {
        catcher(async () => {
            const orders = await businessOrderService.getOrderHistory(counterPasscode, date.toISOString().substring(0, 10));
            setOrders(orders.reverse());
        });
    }

    const refundOrder = async (id) => {
        catcher(async () => {
            await businessOrderService.refundOrder(id);
            getOrderHistory();
        });
    }

    useEffect(() => {
        getOrderHistory();
        setIsLoading(false);
    }, [date]);

    if (isLoading) {
        return (
            <div
                className="business-container"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <h1>{tr("loading")}</h1>
            </div>
        )
    }

    console.log(orders);

    return (
        <div className="business-container">
            <div style={{width: "6em", padding: "1em"}}>
                <button onClick={() => navigateTo(-1)}>{tr("return")}</button>
            </div>
            <div style={{ display: "flex" }}>
                <div style={{ flex: 8, padding: "2em" }}>
                    <h3 style={{ margin: 5 }}>{tr("history")}</h3>
                    <h5>{tr("day")}</h5>
                    <DatePicker selected={date} onChange={(date) => setDate(date)} />
                    <div style={{height: "1em"}}></div>
                    <div className="order-container">
                        {orders.map(order => {
                            return (
                                <Order
                                    order={order}
                                    action={() => console.log(order._id)}
                                    key={order._id}
                                >
                                    <div
                                        style={{
                                            paddingRight: "1em",
                                            paddingBottom: "1em",
                                            paddingLeft: "1em"
                                        }}
                                    >
                                        <p style={{fontSize: ".7em"}}>id: {order._id}</p>
                                        <p style={{fontSize: ".7em"}}>{tr("time")}: {new Date(order.time).toLocaleTimeString("fi-FI").substring(0, 5)}</p>
                                        {/*<RefundButton order={order} />*/}
                                    </div>
                                </Order>
                            )
                        }
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
}

const RefundButton = (order) => {
    const tr = useTranslate();
    const [showRefundForm, setShowRefundForm] = useState(false);
    if (order.status === ORDER_STATUS.REFUNDED) {
        return (
            <button
                style={{
                    backgroundColor: "red",
                    color: "white"
                }}
                disabled={true}
            >
                {tr("refunded")}
            </button>
        )
    } else {
        return (
            <div>
                {
                    showRefundForm ?
                    <button
                        onClick={() => refundOrder(order._id)}
                    >
                        {tr("confirm")}
                    </button> :
                    <div>
                        <input type="text" />
                        <button onClick={() => setShowRefundForm(true)}>{tr("refund")}</button>
                    </div>
                }
            </div>
        )
    }
}

export default OrderHistoryController;
