import { useEffect, useState } from "react";
import { Input } from "../../ui-elements/basic-ui";
import { formatPrice } from "../../util/format";
import { calculatePrice, findVariantForOption, checkUniqueVariants } from "../../util/functions";
import useTranslate from "../../util/dictionary";

export default function VariantsView ({product, amount, chosenVariants, selectedVariants, setSelectedVariants, editVariant, big}) {
    let hasUniqueVariants = checkUniqueVariants(chosenVariants);

    const [useVariantForEveryProduct, setUseVariantForEveryProduct] = useState(!hasUniqueVariants);
    const [productInEdit, setProductInEdit] = useState(0);

    //When a product is removed, check if the product in edit is still valid
    useEffect(() => {
        if(amount !== 0 && productInEdit >= amount) {
            setProductInEdit(amount - 1);

            const newProductInEditVariants = chosenVariants[amount - 1];
            setSelectedVariants(newProductInEditVariants.map(option => ({variant: findVariantForOption(option, product)._id, option: option._id})));
        }
        if(amount === 1 && useVariantForEveryProduct === false) {
            setUseVariantForEveryProduct(true);
        }
    }, [amount]);

    if(product.variants.length === 0) {
        return null;
    }

    return (
        <div>
            {
                amount > 1 && product.variants.length > 0 && 
                    <UseSameVariantForAll
                        product={product}
                        chosenVariants={chosenVariants}
                        useVariantForEveryProduct={useVariantForEveryProduct}
                        setUseVariantForEveryProduct={setUseVariantForEveryProduct}
                        productInEdit={productInEdit}
                        setProductInEdit={setProductInEdit}
                        editVariant={editVariant}
                    />
            }
            <div style={{marginTop: "1em", marginLeft: ".5em"}}>
                {   //Product select when use the same variant for every product is off
                    !useVariantForEveryProduct ?
                        <ProductSelect
                            amount={amount}
                            productInEdit={productInEdit}
                            setProductInEdit={setProductInEdit}
                            chosenVariants={chosenVariants}
                        />
                        : null
                }
            </div>
            <div style={{marginTop: "1em"}}>
            {
                <Variants
                    product={product}
                    chosenVariants={chosenVariants}
                    selectedVariants={selectedVariants}
                    productInEdit={productInEdit}
                    editVariant={editVariant}
                    useVariantForEveryProduct={useVariantForEveryProduct}
                    big={big}
                />
            }
            </div>
        </div>
    );
}

function Variants ({product, chosenVariants, selectedVariants, productInEdit, editVariant, useVariantForEveryProduct, big}) {
    return product.variants.map(variant => {

        //Check if there are price changes between variants as that will tell if price is shown or not
        const priceChanges = checkForPriceChanges(variant);

        if(variant.type === "boolean" && variant.price === 0) {
            return null;
        }
        return (
            <div key={variant._id}>
                <p
                    style={{
                        marginBottom: "0.25em",
                        marginLeft: ".6em",
                        marginTop: "0.5em",
                        fontSize: "0.6em",
                        color: "var(--highlight)"
                    }}
                >
                    {variant.title}
                </p>
                <div
                    style={{
                        display: "flex", 
                        flexWrap: "wrap",
                        marginLeft: ".5em"
                    }}
                >
                    {/*
                        variant.type === "boolean" ?
                            function() {
                                const varPrice = variant.price && variant.price !== 0;
                                if(priceChanges) {
                                    return (
                                        <div
                                            style={{
                                                height: priceChanges ? "3em" : "1em",
                                                minWidth: "3em",
                                                margin: "0.2em",
                                                fontSize: ".6em",
                                                padding: "0.3em",
                                                textAlign: "center",
                                                color: "var(--light)"
                                            }}
                                        >
                                            <p 
                                                style={{
                                                    marginTop: "0.25em",
                                                    fontSize: "1.25em",
                                                    color: "var(--highlight)",
                                                    opacity: varPrice ? 1 : 0.8
                                                }}
                                            >
                                                {formatPrice(calculatePrice(product, [...(selectedVariants || []), variant]))}
                                            </p>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            }()
                        :*/
                            <VariantOptions
                                product={product}
                                variant={variant}
                                chosenVariants={chosenVariants}
                                selectedVariants={selectedVariants}
                                productInEdit={productInEdit}
                                editVariant={editVariant}
                                useVariantForEveryProduct={useVariantForEveryProduct}
                                priceChanges={priceChanges}
                                big={big}
                            />
                        }
                </div>
            </div>
        );
    });
}

function VariantOptions ({product, variant, chosenVariants, selectedVariants, productInEdit, editVariant, useVariantForEveryProduct, priceChanges, big}) {
    const variantsForCurrent = chosenVariants?.[!useVariantForEveryProduct ? productInEdit : 0];
    let chosenOptionsForOtherVariants;
    if(chosenVariants.length > 0) {
        chosenOptionsForOtherVariants = (variantsForCurrent || []).filter(chosenVariant => findVariantForOption(chosenVariant, product)._id !== variant._id);
    } else {
        const filteredSelectedVariants = selectedVariants?.filter(selectedVariant => selectedVariant.variant !== variant._id);
        chosenOptionsForOtherVariants = filteredSelectedVariants?.map(selectedVariant => ({...selectedVariant, price: product.variants.find(v => v._id === selectedVariant.variant).options.find(o => o._id === selectedVariant.option).price}));
    }

    return variant.options.map(option => {                                            
        const chosen = chosenVariants.length > 0 ?
            variantsForCurrent?.find(chosenVariant => chosenVariant._id === option._id) :
            selectedVariants?.find(selectedVariant => selectedVariant.option === option._id);

        const optPrice = option.price && option.price !== 0;
        
        return (
            <div
                key={option._id}
                style={{
                    ...(big ? {flex: 1} : {}),
                    width: "6em",
                    minHeight: "3em",
                    margin: "0.2em",
                    fontSize: ".5em",
                    padding: "0.5em",
                    textAlign: "center",
                    color: chosen ? "var(--medium)" : "var(--light)",
                    backgroundColor: chosen ? "var(--highlight)" : "var(--medium-light)",
                    fontWeight: chosen ? "bold" : "normal",
                    borderRadius: "1em",
                    overflow: "hidden"
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    editVariant(!useVariantForEveryProduct ? productInEdit : null, variant._id, option._id);
                }}
            >
                <p>{option.name}</p>
                {
                    priceChanges &&
                    <p 
                        style={{
                            marginTop: "0.25em",
                            fontSize: "1.25em",
                            fontWeight: "normal",
                            color: chosen ? "var(--medium)" : ( optPrice ? "var(--highlight)" : "var(--highlight-semi)" ),
                            opacity: optPrice ? 1 : 0.8
                        }}
                    >
                        {formatPrice(calculatePrice(product, [...(chosenOptionsForOtherVariants || []), ...(optPrice ? [option] : [])]))}
                    </p>
                }
                {
                big && option.description &&
                <p
                    style={{
                        fontSize: "1em",
                        fontStyle: "italic",
                        margin: "1em",
                        color: chosen ? "var(--medium)" : "var(--light)"
                    }}
                > {option.description} </p>
                }
            </div>
        );  
    });
}

function checkForPriceChanges (variant) {
    return variant.options.reduce((acc, option) => {

        let hasPriceChanges = false;
        /*if(variant.type === "boolean") {
            hasPriceChanges = variant.price && variant.price !== 0;
        } else {*/
            if(option.price && option.price !== 0) {
                return true;
            } else {
                return acc;
            }
    }, false);
}

function UseSameVariantForAll ({product, chosenVariants, useVariantForEveryProduct, setUseVariantForEveryProduct, productInEdit, setProductInEdit, editVariant}) {
    const tr = useTranslate();

    return (
        <div style={{display: "flex", alignContent: "center", alignItems: "center", paddingLeft: ".5em", marginTop: ".5em"}}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Input
                type="checkbox"
                style={{marginRight: "0.5em", marginLeft: "0.5em"}}
                checked={useVariantForEveryProduct}
                onChange={(e) => {
                    if(e.target.checked) {
                        product.variants.forEach((variant, i) => {
                            editVariant(null, variant._id, chosenVariants[productInEdit][i]._id);
                        });
                        setProductInEdit(0);
                    }
                    setUseVariantForEveryProduct(e.target.checked);
                }}
                
            /><span style={{fontSize: "0.5em"}}>{tr("use_variant_for_every_product")}</span>
        </div>
    );
}

function ProductSelect({amount, productInEdit, setProductInEdit, chosenVariants}) {
    const tr = useTranslate();
    return new Array(amount).fill(0).map((_, i) => (
        <div
            key={i}
            style={{
                minHeight: "3em",
                minWidth: "3em",
                display: "inline-block",
                margin: "0.2em",
                fontSize: ".6em",
                padding: "0.5em",
                textAlign: "center",
                color: productInEdit === i ? "var(--medium)" : "var(--light)",
                backgroundColor: productInEdit === i ? "var(--highlight)" : "var(--medium-light)",
                fontWeight: productInEdit === i ? "bold" : "normal",
                borderRadius: "1em",
            }}
            onClick={(e) => {
                e.stopPropagation();
                setProductInEdit(i);
            }}
        >
            <p>{tr("product")}:{i + 1}</p>
            <p>
            {
                chosenVariants[i] && chosenVariants[i].map(v => v.name).join(" - ")
            }
            </p>
        </div>
    ))
}