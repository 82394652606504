import { formatPrice } from "./format";
import uuid from "react-uuid";

export function calculatePrice(product, options) {
  let price = product.price + (product.split_tax?.price || 0);

  if(options) {
    for(let option of options) {
        price += option.price || 0;
    }
  }

  return price;
}

export const findVariantForOption = (option, product) => {
  return product.variants.find(v => v.options.find(o => o._id === option._id));
}

export const calculateTotalFromShoppingCart = (shoppingCart) => {
  if (!shoppingCart || !shoppingCart.items || shoppingCart.items.length === 0) return formatPrice(0);

  return shoppingCart.items.reduce((acc, item) => 
    acc + calculatePrice(item.product) * item.amount
    + (item.variants ? 
        item.variants.filter(v => !!v).reduce((vAcc, vItem) => 
          vAcc + vItem.reduce((v2Acc, v2Item) => v2Acc + v2Item.price, 0)
        , 0)
      : 0)
    ,0);
};

export function stringToBoolean(str) {
  if (str === "true") {
    return true;
  } else if (str === "false") {
    return false;
  } else {
    throw new Error("Invalid boolean string");
  }
}

export function checkUniqueVariants (chosenVariants) {
  let hasUniqueVariants = false;
  if(chosenVariants.length > 1) {
      hasUniqueVariants = chosenVariants.reduce((acc, chosenVariant) => {
          if(JSON.stringify(chosenVariant) !== JSON.stringify(chosenVariants[0])) {
              return true;
          } else {
              return acc;
          }
      }, false);
  }

  return hasUniqueVariants;
}

export const localId = () => {
  const id = uuid();
  return {
    _id: id,
    toJSON: function () {
      const { _id, ...rest } = this;
      return rest;
    }
  }
}

export const getTimeToClosing = (restaurant) => {
  const currentTime = new Date();

  const weekdayUS = currentTime.getDay();
  const weekday = weekdayUS === 0 ? 6 : weekdayUS-1;

  const closingTime = restaurant.closingTimes?.[weekday];

  if (!closingTime) return null;

  const closingTimeDate = new Date();
  closingTimeDate.setHours(closingTime.split(":")[0]);
  closingTimeDate.setMinutes(closingTime.split(":")[1]);
  closingTimeDate.setSeconds(0);

  const diff = closingTimeDate - currentTime;

  if(diff > -60 * 1000 * 5 && diff < 0) return "0:00";

  if(diff < 0 || diff > 60 * 1000 * 5) return null;

  return "0" + Math.floor(diff / 1000 / 60) + ":" + String(diff / 1000 % 60).padStart(2, '0');
}

export function organizeByCategories(products) {

  const organized = {};

  products.forEach(product => {
      if(product.tags.length === 0) {
          if (!organized["Other"]) {
              organized["Other"] = {tag: {name: "Other", translations: {"fi": "Muut"}}, products: []};
          }
          organized["Other"].products.push(product);
      }
      product.tags.forEach(tag => {
          if (!organized[tag.name]) {
              organized[tag.name] = {tag, products: []};
          }
          organized[tag.name].products.push(product);
      })
  });

  return organized;

}

export function getTranslatedTag(tag, language, tmp) {
  return tag.translations?.[language] ? tag.translations[language] : tag.name
}

export function isTodayAccountingMidnight (date) {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  const accountForMidnight = today.getHours() < 10;

  return date === today.toDateString() || (date === yesterday.toDateString() && accountForMidnight);
}