import feedbackService from "../services/feedbackService";
import useTranslate from "../util/dictionary"

export default function Feedback () {
    const tr = useTranslate();

    const submitFeedback = async (e) => {
        e.preventDefault();
        const data = {
            email: e.target.email.value,
            message: e.target.message.value
        };
        await feedbackService.sendFeedback(data);
        e.target.email.value = "";
        e.target.message.value = "";
    }

    return (
        <div style={{width: "10em", maxWidth: "100%", margin: "auto", marginTop: "5em"}}>
            <form onSubmit={submitFeedback}>
                <p style={{fontSize: ".75em"}}>{tr("feedback_plea")}</p>
                <label>{tr("optional_email")}:</label>
                <input type="email" name="email" />
                <label>{tr("message")}:</label><br/>
                <textarea
                    name="message"
                    style={{resize: "none", height: "10em", width: "90%"}}
                />
                <button type="submit">{tr("send_feedback")}</button>
            </form>
        </div>
    );
}