import useTranslate from "../util/dictionary";
import { Mobile, Container, Icon } from "../ui-elements/basic-ui";
import {FullscreenSpinner} from "../ui-elements/spinner";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useState, useEffect, useRef } from "react";
import customerService from "../services/customerService";
import { useParams, useNavigate } from 'react-router-dom';
import { createBrowserNotification } from "../util/notification";
import ConfirmSlider from "../ui-elements/confirm-slider";
import Header from "../ui-elements/header";
import ErrorPage from "./errorPage";

export default function OrderPage(props) {

    const { restaurantId, orderId } = useParams();

    const [order, setOrder] = useState();
    const [queue, setQueue] = useState(1);
    const [error, setError] = useState();
    
    const updateOrder = async () => {
        try {
            const {order, queue} = await customerService.checkOrder(restaurantId, orderId);
            setOrder(order);
            setQueue(queue);
            setError(null);
        } catch (e) {
            setOrder(null);
            setQueue(1);
            setError(e.response.data);

            //Delete shopping cart if order is already delivered TODO: Move to create order
            if(e.response.data === "order_already_delivered" || e.response.data === "order_not_paid") {
                const shoppingCart = JSON.parse(localStorage.getItem("shoppingCart"));
                if(!shoppingCart) {
                    return;
                }
                if(shoppingCart.order === orderId) {
                    localStorage.removeItem("shoppingCart");
                }
            }
        }
    }

    useEffect(() => {
        updateOrder();
        const ticker = setInterval(updateOrder, 2000);

        return () => clearInterval(ticker);
    }, [restaurantId, orderId])

    if (error) {
        return <ErrorPage error={error} navigateTo={restaurantId}/>;
    }

    return (
        <Mobile footerGraphics pad>
            <Header/>
            {!order && !error ?
                <FullscreenSpinner /> :
                null
            }
            {order &&
                <Order
                    order={order}
                    queue={queue}
                    restaurantId={restaurantId}
                />}
        </Mobile>
    );
}

function Order({ order, restaurantId, queue }) {

    const navigate = useNavigate();
    const tr = useTranslate();

    const queueArray = new Array(queue).fill("solid");
    queueArray[0] = "you";

    //Check if user has set notification settings, if not then redirect to notification settings
    useEffect(() => {
        const setNotifications = async () => {
            const shoppingCart = JSON.parse(localStorage.getItem("shoppingCart"));
            const notificationSetting = localStorage.getItem("notificationSetting");

            if((notificationSetting === null || notificationSetting === undefined) && window.Notification !== undefined) {
                navigate("/notifications")
            } else {
                if(notificationSetting === "true") {
                    const payload = await createBrowserNotification();
                    try {
                        await customerService.subscribeToNotifications(
                            shoppingCart.order,
                            {...payload, tr: tr("notification_package_object")}
                        )
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        }

        setNotifications();
    }, []);

    function markReceived() {
        customerService.markReceived(order._id);
        localStorage.setItem("shoppingCart", null);
        navigate(`/${restaurantId}`);
    }

    const illegalOrder = order._id !== JSON.parse(localStorage.getItem("shoppingCart"))?.order;

    return (
        <>
            <h3 style={{marginTop: "0em", marginBottom: "0.6em", fontSize: "0.9em"}}>
                {order.status === "READY" ? tr("drinks_are_ready") : tr("thanks_for_order")}
            </h3>
            <Container className={order.status === "READY" ? "blink" : ""}>
                <h5
                    className="highlight"
                    style={{marginTop: "0.2em", marginBottom: "1.7em", fontSize: "0.7em"}}
                >
                    {
                        order.status === "READY" ?
                            order.table ? tr("order_ready_delivery") : tr("order_ready") :
                            (
                                order.status === "IN_PROGRESS" ?
                                    tr("order_in_progress") : tr("ordered_drinks_soon_ready")
                            )
                    }
                    {
                        
                    }
                </h5>
                {
                    order.items[0].product.image &&
                    <div style={{display: "flex", alignItems: "center", margin: "-16px", backgroundColor: "#fff", height: "6em"}}>
                        <img src={order.items[0].product.image || ""} alt={order?.items[0].product.name} style={{maxHeight: "6em", margin: "auto"}}/>
                    </div>
                }
                <div style={{marginTop: "1.3em", marginBottom: "0.5em"}}>
                    {order?.items.map(item => 
                        <p key={item._id} style={{marginTop: ".3em", marginBottom: ".3em"}}>
                            {item.product.name + (item.amount > 1 ? " x" + item.amount : "")}
                        </p>
                    )}
                </div>
                <h5 className="highlight" style={{margin: 0}}>{tr("order_code_is")}</h5>
                <div
                    id="delivery-code"
                    className={order.status === "READY" ? "ready" : ""}
                    style={illegalOrder ? {borderColor: "#FF8888", color: "#"} : {}}
                >
                    <div>{order.deliveryId.toUpperCase()}</div>
                </div>
                {/*
                    illegalOrder &&
                    <p style={{
                        color: "#FF8888",
                        marginTop: "0.25em",
                        fontSize: "0.8em"
                    }}>{tr("different_browser_warning")}</p>
                */}
                {
                    order.status !== "READY" ?
                        order.status !== "IN_PROGRESS" ?
                            (
                                <TransitionGroup style={{marginBottom: ".6em"}}>
                                    <p style={{marginBottom: 0, marginTop: "1em", fontSize: "0.75em"}}>{tr("queue_status")}: {queue}</p>
                                    {
                                        queueArray.slice(0, 10).map((elem, i) => (
                                            <CSSTransition key={i} timeout={500} classNames="queuedot">
                                                <div className={`queuedot ${elem} noselect`}>
                                                    {(i === 9 && queueArray.length > 10) ? `+${queueArray.length-10}` : ""}
                                                </div>
                                            </CSSTransition>
                                        ))
                                    }
                                </TransitionGroup>
                            ) :
                            <p style={{color: "var(--highlight)", margin: "1em", fontSize: ".75em"}}>{tr("making_drink")}</p>
                    :
                    <div>
                        <p style={{
                            color: "var(--highlight)",
                            marginBottom: "0.4em",
                            fontSize: "0.6em",
                            marginTop: "1em"
                        }}>
                            {tr("ready")}
                        </p>
                        <Icon icon="ready" yellow/>
                    </div>
                }
            </Container>
            {
                order.status !== "READY" && window.Notification !== undefined &&
                    <button
                        onClick={() => navigate("/notifications")}
                        style={{
                            marginTop: "1.5em"
                        }}
                    >
                        <span><b>{tr("notification_options")}</b></span><br />
                    </button>
            }
            {
                order.status === "READY" ?
                    <ConfirmSlider
                        onSlide={markReceived}
                        text={tr("slide_to_mark_received")}
                    /> :
                    null
            }
        </>
    );
}
