import { Container } from '../ui-elements/basic-ui';
import { useState } from "react";
import useTranslate from '../util/dictionary';
import { TagListing } from './product';
import OrderedVariantsView from '../common/orderedVariantsView';

export default function Order({order, action, children}) {
    const tr = useTranslate();
    const [fading, setFading] = useState(false);

    return (
        <>        
            <Container
                className={
                    "flex-direction-by-screensize " +
                    (fading ? "order-fade-out" : "order-fade-in")}
                style={{
                    width: "100%",
                    minWidth: "100%",
                    minHeight: "3em",
                    padding: 0,
                    textAlign: "left",
                    position: "relative",
                    userSelect: "none",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "16px"
                }}
                onClick={() => {
                    setFading(true);
                    setTimeout(() => action(), 5);
                }}
            >
                <div
                    style={{
                        backgroundColor: "white",
                        height: "100%"
                    }}
                >
                    {order.items[0].product.image &&
                        <img
                            src={order.items[0].product.image}
                            style={{
                                height: "80px",
                                objectPosition: "center",
                            }}
                        />
                    }
                </div>
                <div style={{ flex: 3, paddingLeft: "8px" }}>
                    <h4
                        style={{
                            margin: "0.5em 0.5em 0 0.5em",

                            fontSize: "0.8em"
                        }}
                    >
                        {order.items.map((item, i) => 
                            <span style={{display: "block"}} key={i}>{item.product.name + (item.amount > 1 ? " x" + item.amount : "")}
                            <TagListing product={item.product} hideCategoryless={true} inline={true}/>
                            <OrderedVariantsView variants={item.variants} product={item.product} showPrice={false} />
                            {/*<span style={{fontSize: "0.7em", margin: 0, display: "block", color: "var(--highlight)"}}>{(item.variants && item.variants.length > 0 ? item.variants.map(vrnt => vrnt.map(v => v.name).join(" - ")).join(", ") : "")}</span>*/}</span>
                        )}
                    </h4>
                    <h6
                        className="highlight"
                        style={{ margin: "0 0.5em 0.5em 0.5em", fontSize: "18px"}}
                    >
                        {order.deliveryId.toUpperCase()}
                    </h6>
                    {children}
                </div>
                {
                    order.additionalInfo && order.additionalInfo.length > 0 ? (
                        <div style={{ flex: 4 }}>
                            <p style={{fontSize: "0.75em"}}>
                                {order.additionalInfo}
                            </p>
                        </div>
                    ) : null
                }
                <div>
                {order && order.table && order.table.code !== null ? (
                    <h5 style={{marginRight: "1em"}}>
                        {tr("table")} {order.table.code}
                    </h5>
                    ) : null
                }
                {
                    order && order.time && (
                        <h5 style={{marginRight: "1em", color: "var(--highlight)"}}>
                            {new Date(order.time).toLocaleTimeString("de-DE").substring(0, 5)}
                        </h5>
                    )
                }
                </div>
            </Container>
            
        </>
    );
}