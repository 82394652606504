import { useState } from 'react';
import { Icon } from '../../ui-elements/basic-ui';
import useTranslate from '../../util/dictionary';
import { getTranslatedTag } from '../../util/functions';

export function Search ({search, setSearch}) {
    const tr = useTranslate();

    return (
        <div style={{
            display: "flex", 
            alignItems: "center", 
            borderRadius: "16px", 
            width: "60%", 
            margin: "auto", 
            backgroundColor: "var(--medium)",
            paddingLeft: "0.5em",
        }}>
            <input
                style={{
                    flex: 1,
                    border: "none",
                    outline: "none",
                    fontSize: ".75em",
                    backgroundColor: "transparent",
                }}
                placeholder={tr("search")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            { search.length === 0 ?
                <Icon icon="search" style={{marginLeft: "1em", marginRight: "1em"}} /> :
                <div onClick={() => setSearch("")} style={{cursor: "pointer"}}>
                    <Icon icon="close" style={{marginLeft: "1em", marginRight: "1em", marginTop: "0.25em"}}/>
                </div>
            }
        </div>
    );
}

export function constructSearchTag (search, productsByTags, language) {

    function createProductArray(productsByTags, search) {
        return Object.values(productsByTags).map(p => p.products.reduce((acc, product) => {
            if( product.name.toLowerCase().includes(search.toLowerCase()) || product.tags.some(tag => getTranslatedTag(tag, language).toLowerCase().includes(search.toLowerCase()))) {
                return [...acc, product];
            } else {
                return acc;
            }
        }, [])).flat();
    }

    let productArray = createProductArray(productsByTags, search);

    
    //few special cases for Finnish plurals
    if(search === "olut" || search === "kalja") {
        productArray = productArray.concat(createProductArray(productsByTags, "oluet"));
    }

    //Sort by first having name beginning with search, then words beginning with search, then words containing search, then tags beginning with search, then tags containing search
    const productArrayWithPriority = productArray.map(product => {
        const name = product.name.toLowerCase();
        const priority = name.startsWith(search.toLowerCase()) ? 0 : 
                        name.split(" ").some(word => word.startsWith(search.toLowerCase())) ? 1 : 
                        name.split(" ").some(word => word.includes(search.toLowerCase())) ? 2 : 
                        product.tags.some(tag => getTranslatedTag(tag, language).startsWith(search.toLowerCase())) ? 3 : 4;
        return {product, priority};
    });

    productArrayWithPriority.sort((a, b) => a.priority - b.priority);

    return [{
        products: productArrayWithPriority.map(p => p.product),
        tag: {
            name: "Search",
            priority: 0,
            _id: "1234567890"
        }
    }];
}