import { formatPrice } from "../util/format";
import { findVariantForOption, checkUniqueVariants } from "../util/functions";

export default function OrderedVariantsView ({variants, product, showPrice}) {
    if(!variants) return null;
    
    let titledVariants = variants.map(variant => variant.map(v => ({...v, title: findVariantForOption(v, product)?.title})));


    const hasUnique = checkUniqueVariants(variants);

    if(!hasUnique && titledVariants.length > 1) {
        titledVariants = [titledVariants[0]];
    }

    return (
        <div style={{
            textAlign: "left"
        }}>
            {
                titledVariants.map((variant) => {
                    const price = variant.reduce((acc, v) => acc + v.price, 0);
                    return (
                        <div key={variant._id} style={{
                            margin: "0.5em",
                            backgroundColor: "var(--medium-light)",
                            padding: "0.5em",
                            borderRadius: "0.5em",
                            fontSize: "0.75em"
                        }}>
                            <p className="highlight" style={{fontWeight: "bold"}}>
                                {
                                    showPrice && hasUnique ?
                                        formatPrice(product.price + (product.split_tax?.price || 0) + price) :
                                        ""
                                }
                            </p>
                            {
                                variant.map(v =>
                                    <p>
                                        <span className="highlight">{v.title}</span>:  {v.name}
                                    </p>
                                )
                            }
                        </div>
                    );
                })
            }
        </div>
    );
}