import { useState, useEffect } from 'react';
import OrderContainer from "./ordercontainer";
import businessOrderService from '../services/business/orderService';
import catcher from '../util/catcher';
import { ORDER_STATUS } from '../util/constants';
import OrderNavbar from './ordernavbar';
import BackButton from '../common/backbutton';
import useTranslate from '../util/dictionary';
import counterService from '../services/business/counterService';
import { useNavigate, useParams } from 'react-router-dom';
import { FullscreenSpinner } from "../ui-elements/spinner";
import { toastError } from '../util/toast';

export default function OrderController () {

    const { counterPasscode } = useParams();
    const tr = useTranslate();
    
    const [orders, setOrders] = useState([]);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [counter, setCounter] = useState(null);

    const getOrders = async () => {
        catcher(async () => {
            const orders = await businessOrderService.getOrders(counterPasscode);
            setOrders(orders);
        });
    }

    useEffect(() => {
        getOrders();
        const ticker = setInterval(getOrders, 3000);
        setIsLoading(true);
        counterService.getCounter(counterPasscode).then(counter => {
            setOpen(counter.open);
            setCounter(counter);
            setIsLoading(false);
        });
        return () => clearInterval(ticker);
    }, []);

    const openCounter = async () => {
        counterService.openCounter(counterPasscode);
        setOpen(true);
    };

    const closeCounter = async () => {
        counterService.closeCounter(counterPasscode);
        setOpen(false);
    };

    const markReady = async (id) => {
        try {
            await businessOrderService.markReady(id);
            getOrders();
        } catch (e) {
            console.log(e);
            toastError(tr("error"));
        }
    };

    const markInProgress = async (id) => {
        try {
            await businessOrderService.markInProgress(id);
            getOrders();
        } catch (e) {
            console.log(e);
            toastError(tr("error"));
        }
    }

    const markDelivered = async (id) => {
        try {
            await businessOrderService.markDelivered(id);
            getOrders();
        } catch (e) {
            console.log(e);
            toastError(tr("error"));
        }
    };

    if (isLoading) {
        return (
            <FullscreenSpinner/>
        );
    }

    return (
        <div>
            <OrderNavbar closeCounter={closeCounter} openCounter={openCounter} open={open}/>
            {!open &&
                <div style={{
                    backgroundImage: "linear-gradient(180deg, rgba(255,0,0,0.7) 0%, rgba(255,0,0,0.5) 50%, rgba(0,0,0,0) 100%)",
                    width: "100%",
                    height: "40vh",
                    position: "absolute",
                    opacity: 0.3
                }}/>
            }
            <div style={{backgroundColor: "black", minHeight: "100vh"}}>
                {!open ?
                    <div style={{backgroundColor: "var(--black)", paddingTop: "1em"}}>
                        <h3 style={{textAlign: "center"}}>{tr("counter_closed")}</h3>
                    </div> :
                    null
                }
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    <div style={{flex: 1, padding: "1em", minWidth: "10em"}}>
                        <h3 style={{margin: 5}}>{tr("new_orders")}</h3>
                        <OrderContainer
                            type="todo"
                            orders={orders.filter(order => order.status !== ORDER_STATUS.READY && order.status !== ORDER_STATUS.IN_PROGRESS)}
                            action={markInProgress}
                        />
                    </div>
                    <div style={{flex: 1, padding: "1em", minWidth: "10em"}}>
                        <h3 style={{margin: 5}}>{tr("in_progress")}</h3>
                        <OrderContainer
                            type="in_progress"
                            orders={orders.filter(order => order.status === ORDER_STATUS.IN_PROGRESS)}
                            action={markReady}
                        />
                    </div>
                    <div style={{flex: 1, padding: "1em", minWidth: "10em"}}>
                        <h3 style={{margin: 5, textWrap: "nowrap"}}>{counter?.tableService === "Only" ? tr("ready_to_deliver") : tr("fetchable")}</h3>
                        <OrderContainer
                            type="finished"
                            orders={orders.filter(order => order.status === ORDER_STATUS.READY)}
                            action={markDelivered}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}