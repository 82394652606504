import { Container, Input, Button, Icon } from "../../ui-elements/basic-ui";
import { PlusMinusButton } from "../../ui-elements/theme-button";
import { formatPrice } from "../../util/format";
import { calculatePrice, checkUniqueVariants } from "../../util/functions";
import VariantsView from "./variantsView";
import OrderedVariantsView from "../../common/orderedVariantsView";

export default function ProductCard({
    product, style, sideways, lineAmt, openModal, fontSize = 1,
    amount, chosenVariants, selectedVariants, setSelectedVariants, editVariant, addAction, removeAction, big
}) {
    if (sideways) {
        return SidewaysProductCard({
            product, style, lineAmt, openModal, fontSize, amount, chosenVariants, editVariant, addAction, removeAction
        });
    }

    return (
        <>
            <Card
                product={product}
                style={style}
                lineAmt={lineAmt}
                openModal={openModal}
                fontSize={fontSize}
                bottom={
                    <div>
                        <VariantsView
                            product={product}
                            chosenVariants={chosenVariants}
                            selectedVariants={selectedVariants}
                            setSelectedVariants={setSelectedVariants}
                            editVariant={editVariant}
                            amount={amount}
                            big={big}
                        />
                        <div style={{minHeight: "50px", padding: "0.2em"}}>
                            <div
                                style={{
                                    position: "absolute",
                                    right: "0.2em",
                                    bottom: "0.2em",
                                }}
                            >
                                <PlusMinusButton
                                    add={addAction}
                                    remove={removeAction}
                                    amount={amount || 0}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    );
}

export function Card({product, style, lineAmt, openModal, fontSize = 1, bottom, greyed}) {
    return (
        <Container
            style={{
                width: "12em",
                minWidth: "12em",
                padding: 0,
                textAlign: "left",
                position: "relative",
                userSelect: "none",
                cursor: "pointer",
                marginLeft: 0,
                marginRight: "32px",
                opacity: greyed ? 0.5 : 1,
                ...style
            }}
            onClick={openModal ? () => openModal(product) : () => {}}
        >
            { product.image &&
                <div
                    style={{
                        backgroundColor: "white",
                        width: "100%",
                        height: "6em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <img
                        src={product.image}
                        style={{
                            height: "100%",
                            margin: "auto"
                        }}
                    />
                </div>
            }
            <div style={{ display: "flex" }}>
                <div 
                    style={{
                        flex: 3,
                        paddingLeft: `${8 * fontSize}px`,
                        overflow: "hidden", 
                         margin: "0.5em"
                    }}
                >
                    <h4
                        style={{
                            fontSize: 20 * fontSize
                        }}
                    >{product.name}</h4>
                    {
                        product.alcohol_content !== null &&
                        product.alcohol_content !== undefined &&
                        product.alcohol_content !== "" ?
                        <h6
                            style={{
                                fontSize: 15 * fontSize,
                                color: "var(--highlight)"
                            }}
                        >
                            {product.alcohol_content.toFixed(1)}%
                        </h6> :
                        null
                    }
                </div>
                <div style={{
                    flex: 1,
                    textAlign: "right",
                    paddingRight: `${8 * fontSize}px`
                }}>
                    <h4
                        className="highlight"
                        style={{
                            margin: "0.5em",
                            marginBottom: 0,
                            fontSize: 20 * fontSize
                        }}
                    >
                        {formatPrice(calculatePrice(product))}
                    </h4>
                    <h6
                        style={{
                            marginRight: "0.5em",
                            marginBottom: "0.5em",
                            fontSize: 15 * fontSize
                        }}
                    >
                        {product.size}
                    </h6>
                </div>
            </div>
            <div
                style={{
                    display: '-webkit-box', /* For webkit browsers */
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: lineAmt ? lineAmt : 3, /* Show two lines of text */
                    overflow: 'hidden',
                    marginLeft: 4
                }}
            >
                <p
                    style={{
                        marginLeft: "0.5em",
                        marginTop: "0em",
                        fontSize: fontSize * 14,
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        maxWidth: "100%",
                        whiteSpace: "pre-line"
                    }}
                >{product.description}</p>
            </div>
            <div style={{alignSelf: "flex-end", marginBottom: "0.25em"}}>
                {bottom}
            </div>
        </Container>
    );
}

function SidewaysProductCard({
    product, style, openModal, fontSize = 1,
    amount, chosenVariants, addAction, removeAction
}) {
    return (
        <SidewaysCard
            product={product}
            style={style}
            openModal={openModal}
            fontSize={fontSize}
            amount={amount}
            chosenVariants={chosenVariants}
            bottom={
                <div style={{
                    flex: 2,
                    textAlign: "right",
                    paddingRight: `${8 * fontSize}px`,
                    minWidth: "4em"
                }}>
                    <div style={{ float: "right" }}>
                        <PlusMinusButton
                            add={addAction}
                            remove={removeAction}
                            amount={amount || 0}
                        />
                    </div>
                </div>
            }
        />
    );
}

export function SidewaysCard ({product, chosenVariants, fontSize, bottom}) {
    return (
        <Container
            style={{
                width: "100%",
                minWidth: "100%",
                minHeight: "4em",
                padding: 0,
                textAlign: "left",
                position: "relative",
                userSelect: "none",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "16px",
                display: "flex",
                flexDirection: "row"
            }}>
            {product.image && 
                <div
                    style={{
                        flex: 2,
                        overflow: "hidden",
                        height: "4em",
                        backgroundColor: "white"
                    }}
                >
                    <img
                        src={product.image}
                        style={{
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center"
                        }}
                    />
                </div>
            }
            <div style={{ flex: 3, paddingLeft: `${8 * fontSize}px` }}>
                <h4 style={{ margin: 0, fontSize: 25 * fontSize }}>{product.name}</h4>
                {
                    (!chosenVariants || chosenVariants.length <= 1 || !checkUniqueVariants(chosenVariants)) &&
                        <h6
                            className="highlight"
                            style={{ margin: 0, fontSize: 18 * fontSize }}
                        >{formatPrice(calculatePrice(product, chosenVariants && chosenVariants.length > 0 ? chosenVariants[0] : null))}</h6>
                }
                <OrderedVariantsView variants={chosenVariants} product={product} showPrice={true} />
            </div>
            <div style={{flex: 3, textAlign: "left"}}>
                {bottom}
            </div>
        </Container>
    );
}
