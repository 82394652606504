import { useEffect, useState } from "react";
import useTranslate from "../util/dictionary";
import customerService from "../services/customerService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SHOPPING_CART_STATUS } from "../util/constants";
import { useLocalStorageState } from "../hooks/localStorageHook";
import { Mobile, Button, Icon } from "../ui-elements/basic-ui";
import Header from "../ui-elements/header";
import ProductCard from "./ProductCard/productCard";
import { calculateTotalFromShoppingCart } from "../util/functions";
import { formatPrice } from "../util/format";
import Modal from "../ui-elements/modal";
import ErrorPage from "./errorPage";
import { Input } from '../ui-elements/basic-ui'
import { useLanguageContext } from "../context/language";

export default function ShoppingCart() {
    const languageContext = useLanguageContext();
    const language = languageContext ? languageContext.language : "fi";
    const tr = useTranslate();

    const navigate = useNavigate();
    const [query, setQuery] = useSearchParams();
    let table = query.get("table");
    if (isNaN(table) || table < 0 || table > 999) {
        table = null;
    }

    const [shoppingCart, setShoppingCart] = useLocalStorageState("shoppingCart", {});
    const [shoppingHistory, setShoppingHistory] = useLocalStorageState("shoppingHistory", []);
    const [deliveryMethod, setDeliveryMethod] = useState(table ? "delivery" : "fetch");
    const [email, setEmail] = useState("");
    const [validationError, setValidationError] = useState();
    const [tableCode, setTableCode] = useState(table);
    const [tableError, setTableError] = useState(false);
    const [tables, setTables] = useState([]);
    const [counter, setCounter] = useState(null);
    const [tableModalOpen, setTableModalOpen] = useState(false);
    const [termsOfDeliveryModalOpen, setTermsOfDeliveryModalOpen] = useState(false);
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [error, setError] = useState();
    const [loadingCreateOrder, setLoadingCreateOrder] = useState(false);
    const [showReceiptInput, setShowReceiptInput] = useState(false);

    useEffect(() => {
        const fetchTables = async () => {
            if (shoppingCart.restaurantId) {
                const counter = await customerService.getOrderPage(shoppingCart.restaurantId);
                setCounter(counter);
                const res = await customerService.getTables(shoppingCart.restaurantId);
                setTables(res);
            }
        };
        fetchTables();
    }, [shoppingCart]);

    useEffect(() => {
        if(showReceiptInput === false) {
            setValidationError(undefined)
        }
    }, [showReceiptInput]);

    const emptyShoppingCart = () => {
        setShoppingCart({});
        setTimeout(() => {
            navigate("/" + shoppingCart.restaurantId + (table ? "/" + table : ""));
        }, 100);
    };

    const openTermsOfDelivery = () => {
        setTermsOfDeliveryModalOpen(true);
    }

    const saveTable = () => {
        localStorage.setItem("table", JSON.stringify({created: new Date(), table: tableCode}));
    }

    const createOrder = async () => {
        if (showReceiptInput && email === "") {
            setValidationError('empty_email')
            return;
        }
        if(counter.tableService === "Only" && (!tableCode || tableCode === "-")) {
            setTableError(true);
            return;
        }

        setTableError(false);
        setLoadingCreateOrder(true);

        customerService.createOrder({
            shoppingCart,
            tableCode: deliveryMethod === "delivery" && counter.tableService !== "Unavailable" ? tableCode : null,
            email: !!email ? email : null,
            language,
            additionalInfo
        }).then(({order, paymentUri}) => {
            setShoppingCart({ ...shoppingCart, status: SHOPPING_CART_STATUS.ORDERED, order: order._id, paymentUri });
            setTimeout(() => {
                setLoadingCreateOrder(false);
                window.location = paymentUri;
            });
            setShoppingHistory(shoppingHistory.concat(order._id));
        }).catch((e) => {
            setLoadingCreateOrder(false);
            setError(e.response.data)
        })
    };

    const addToShoppingCart = (product) => {
        product.amount++;
        setShoppingCart({ ...shoppingCart });
    };

    const removeFromShoppingCart = (product) => {
        product.amount--;
        if (product.amount === 0) {
            shoppingCart.items = shoppingCart.items.filter(item => item.product._id !== product.product._id);
        }
        setShoppingCart({ ...shoppingCart });
    };

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const testEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const validateEmail = () => {
        const isValidEmail = !email || testEmail(email)
        if (!isValidEmail) {
            setValidationError('non_valid_email')
        }
        return isValidEmail
    }

    if (!!error) {
        const shoppingCartGuard = shoppingCart && shoppingCart.restaurantId ? shoppingCart.restaurantId : undefined;
        return <ErrorPage error={error} navigateTo={shoppingCartGuard} />
    }

    return (
        <Mobile style={{ textAlign: "left" }} footerGraphics pad>
            <Header backButton={true} override={() => navigate("../" + shoppingCart.restaurantId + (table ? "/" + table : ""))} />
            <h3 style={{ marginTop: "0.2em", marginBottom: "0.7" }}>{tr("checkout")}</h3>
            <h5 className="highlight" style={{ marginTop: 0, marginBottom: "1em" }}>{tr("items")}</h5>
            {
                (!shoppingCart.items || shoppingCart.items.length === 0) &&
                (
                    tr("shopping_cart_empty")
                )
            }
            {
                shoppingCart && shoppingCart.items ? shoppingCart.items.map(product => (
                    <ProductCard
                        sideways
                        key={product.product._id}
                        product={product.product}
                        shoppingCart={shoppingCart}
                        style={{
                            display: "inline-block",
                            margin: "16px"
                        }}
                        openModal={(product) => setModalProduct(product)}
                        amount={product.amount}
                        chosenVariants={product.variants}
                        setProductsInShoppingCart={setShoppingCart}
                        addAction={() => addToShoppingCart(product)}
                        editVariant={() => {}}
                        removeAction={() => removeFromShoppingCart(product)}
                    />
                )) : null}
            { tables.length > 0 && counter && counter.tableService !== "Unavailable" &&
                <>
                    <h5 className="highlight" style={{ marginTop: "1em", marginBottom: "0.4em" }}>{tr("delivery")}</h5>
                    { counter.tableService !== "Only" &&
                        <input type="radio" name="delivery" checked={deliveryMethod === "delivery"} onChange={
                            () => {
                                setDeliveryMethod("delivery");
                                if (!tableCode) {
                                    setTableModalOpen(true);
                                }
                            }
                        }></input>
                    }
                    <span>{tr("to_table_nr")} </span>
                    <button
                        style={{ width: "3em", fontSize: "0.8em", height: "1.5em", paddingTop: "0.2em", marginTop: 0 }}
                        onClick={() => setTableModalOpen(true)}
                    >
                        <b>{tableCode || "-"}</b>
                    </button >
                    <br />
                    { counter.tableService !== "Only" &&
                        <>
                            <input type="radio" name="delivery" checked={deliveryMethod === "fetch"} onChange={() => setDeliveryMethod("fetch")}></input><span>{tr("self_fetch")}</span>
                        </>
                    }
                </>
            }
            {
                shoppingCart?.items?.length > 0 ?
                <div>
                    { counter && !counter.paperReceipts &&
                    <>
                        <h5 className="highlight" style={{ marginTop: "1em", marginBottom: "0.4em" }}>{tr("receipt")}</h5>
                        <input
                            type="radio"
                            name="receipt"
                            onClick={() => setShowReceiptInput(!showReceiptInput)}
                            onChange={() => setShowReceiptInput(!showReceiptInput)}
                            checked={showReceiptInput}
                        />{tr("want_receipt")}
                        {
                            showReceiptInput &&
                            <div>
                                <input
                                    type="radio"
                                    name="receipt"
                                    onChange={() => setShowReceiptInput(false)}
                                />{tr("no_receipt")}
                                <Input
                                    required
                                    id="email"
                                    label="email_for_receipt"
                                    type="email"
                                    name="email"
                                    error={validationError}
                                    onChange={onEmailChange}
                                    onFocus={() => setValidationError(undefined)}
                                    onBlur={validateEmail}
                                    value={email}
                                />
                            </div>
                        }
                        </>
                    }
                    <div
                        style={{
                            marginTop: "1em",
                            marginBottom: "1em",
                            width: "100%"
                        }}
                    >
                        <h5 className="highlight" style={{ marginTop: "1em" }}>
                            {tr("additional_info")}
                        </h5>
                        <Input
                            label="additional_info_detailed"
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                        />
                    </div>
                    <div
                        className="highlight clickable"
                        style={{ fontWeight: "normal", fontStyle: "italic", marginTop: "1em" }}
                        onClick={openTermsOfDelivery}
                    >
                        {tr("terms_of_delivery")}<Icon style={{height: "0.75em"}} icon="arrow-right" yellow="true" />
                    </div> 
                    <button
                        style={{
                            marginTop: "2em",
                            width: "100%",
                            backgroundColor: "#000",
                            color: "#fff",
                            border: "2px solid #fff"
                        }}
                        onClick={() => {
                            emptyShoppingCart();
                        }}
                    >
                        {tr("empty_shopping_cart")}
                    </button>
                    <Button
                        style={{ fontWeight: "normal" }}
                        onClick={createOrder}
                        disabled={!!validationError && !!tableError}
                        loading={loadingCreateOrder}
                    >
                        <span><b>{tr("to_pay")}</b></span><br />
                        {tr("total")} {formatPrice(calculateTotalFromShoppingCart(shoppingCart))}
                    </Button>
                    {
                        tableError &&
                            <span className="error">{tr("no_table_selected")}</span>
                    }
                    <div style={{ height: "2em" }}></div>
                    <TableModal
                        open={tableModalOpen}
                        close={() => setTableModalOpen(false)}
                        tables={tables}
                        setTableCode={(code) => {
                            const params = new URLSearchParams(query);
                            params.set("table", code);
                            setQuery(params);
                            setTableCode(code)
                            setDeliveryMethod("delivery");
                        }}
                    />
                    <Modal style={{maxWidth: "100%"}} open={termsOfDeliveryModalOpen} onClose={() => setTermsOfDeliveryModalOpen(false)}>
                        <div style={{ padding: 16, fontSize: ".75em", maxWidth: "100%" }} className="termsOfDeliveryModal">
                            <h1 className="highlight">{tr("terms_of_delivery")}</h1>
                            {tr("terms_of_delivery_text")}
                        </div>
                    </Modal>
                </div> :
                <>
                    <button
                        onClick={() => {
                            emptyShoppingCart();
                        }}
                    >
                        {tr("return")}
                    </button>
                </>
            }
        </Mobile>
    );
}

function TableModal({ open, close, tables, setTableCode }) {
    const tr = useTranslate();
    return (
        <Modal open={open} onClose={close}>
            <div style={{ padding: 16, width: "8em" }}>
                <h5>
                    {tr("choose_table")}
                </h5>

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gridGap: 8,
                    marginBottom: 16,
                    marginTop: 16
                }}>
                    {tables.map(table => <button
                        key={table.code}
                        onClick={() => {
                            setTableCode(table.code);
                            close();
                        }}
                    >
                        {table.code}
                    </button>)}
                </div>
            </div>
        </Modal>
    );
}